@use "sass:map";
@use "sass:color";
@import url("https://fonts.googleapis.com/css2?family=Syne&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");

$font-heading: "Syne";
$font-content: "Lato";

$theme-colors: (
  "primary": #e67e22,
  "secondary": #371a15,
  "dark": #2e2e2e,
  "light": #fffffc,
);
@import "~bootstrap/scss/bootstrap";
@import "~react-quill/dist/quill.snow.css";
@import "~react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

.alert-toast {
  position: absolute;
  min-width: 100px;
  bottom: 20;
  right: 20;
}

.dropdown-ingredient {
  padding-left: 10px;
  padding-top: 0px;
  padding-right: 10px;
  padding-bottom: 0px;
}

.pill-recipe {
  //text-align: left !important;
  align-items: center;
  :hover {
    color: white !important;
    background-color: color.adjust(map.get($theme-colors, "secondary"), $alpha: -0.1);
  }
  :active {
    color: white !important;
    background-color: color.adjust(map.get($theme-colors, "secondary"), $alpha: -0.2);
  }
  .nav-link.active {
    color: white !important;
    background-color: map.get($theme-colors, "secondary");
  }
}

.ingredient-sugar {
  width: 100%;
  height: 100vh;
  margin-top: 10px;
  border: 1px black red;
  //background-color: rgba(30,85,98,1);
}
.title {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.title h5 {
  width: 99%;
}

//Ingredients Table content
.table-ingredients {
  font-size: 12px;
  tr {
    min-height: 56px;
    max-height: 56px;
  }
  th {
    font-size: 12px;
    font-weight: 400;
    text-decoration-line: underline;
    min-width: 55px;
    height: 60px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .p-col {
    min-width: 50px;
  }
  td {
    font-size: 12px;
    min-width: fit-content inline size;
    height: 56px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .main-td {
    font-weight: bold;
  }
}
.link:hover {
  text-emphasis-style: none;
}

.quantity-text-field-td {
  max-width: 125px;
  width: 125px;
}

.table-row-temp {
  th,
  td {
    text-decoration-line: underline;
    font-size: 12px;
    font-weight: 800;
    height: 42px;
    max-height: 42px;
  }
}

.recipe-container {
  width: "100px";
  background: "transparent";
  margin: "auto";
}

.page-folders {
  padding: 10px 10px 10px 10px;
  //background-color: rgba(30,85,98,1);
}
.table-recipes {
  background-color: transparent;
  margin: 5px 0px 10px 0px;
  width: 100%;
}
.list-ingredients {
  margin: 5px 0px 10px 0px;
}
.info-user {
  width: 100%;
  margin: 10px 10px 10px 10px;
}
.upload {
  text-align: center;
}
.upload:hover {
  text-align: center;
  opacity: 0.8;
}

.loader {
  margin-top: "100px";
  display: "flex";
  justify-content: "center";
  align-items: "center";
}

/*!
 * Start Bootstrap - Simple Sidebar (https://startbootstrap.com/templates/simple-sidebar)
 * Copyright 2013-2020 Start Bootstrap
 * Licensed under MIT (https://github.com/StartBootstrap/startbootstrap-simple-sidebar/blob/master/LICENSE)
 */

#wrapper {
  overflow-x: hidden;
  min-height: 100vh;
}

#sidebar-wrapper {
  z-index: 0;
  min-height: 100vh;
  margin-left: -15rem;
  -webkit-transition: margin 0.25s ease-out;
  -moz-transition: margin 0.25s ease-out;
  -o-transition: margin 0.25s ease-out;
  transition: margin 0.25s ease-out;
  position: fixed;

  .sidebar-heading {
    padding: 0.8rem;
    font-size: 1.2rem;
    color: map.get($theme-colors, "light");
  }

  .list-group {
    width: 15rem;
  }

  .list-group-item {
    background-color: map.get($theme-colors, "secondary");
    color: map.get($theme-colors, "light");
    &:hover {
      background-color: map.get($theme-colors, "primary");
      //color: map.get($theme-colors, "dark");
    }
  }
}

.main-pane {
  padding-top: 10px;
}

#page-content-wrapper {
  z-index: 2;
  margin-left: 0;
  margin-bottom: 0;
  background-color: white;
  -webkit-transition: margin 0.25s ease-out;
  -moz-transition: margin 0.25s ease-out;
  -o-transition: margin 0.25s ease-out;
  transition: margin 0.25s ease-out;
}

#wrapper.toggled {
  #sidebar-wrapper {
    margin-left: 0;
  }

  #page-content-wrapper {
    margin-left: 15rem;
  }
}

.content {
  margin-top: 140px;
}

@media (min-width: 768px) {
  .content {
    margin-top: 75px;
  }
  .gen-navbar {
    display: none;
  }
  #sidebar-wrapper {
    margin-left: 0;
  }

  #page-content-wrapper {
    margin-left: 15rem;
    width: 100%;
  }

  #wrapper.toggled {
    .header {
      margin-left: 3.8rem;
    }

    #sidebar-wrapper {
      margin-left: 0;
    }

    #page-content-wrapper {
      margin-left: 3.8rem;
    }
  }
}

.scrollable-menu {
  height: auto;
  max-height: 200px;
  overflow-x: hidden;
}

//Folder styles
.folder-card {
  display: flex;
  flex-direction: row;
  min-width: 220px;
  max-width: 220px;

  .card-body {
    color: map.get($theme-colors, "dark");
  }

  .folder-icon {
    color: map.get($theme-colors, "primary");
  }

  .dropdown-toggle {
    border: 1px solid transparent;
    padding: 3px 10px 2px 10px;
    background-color: transparent;
    color: map.get($theme-colors, "dark");
    &:hover {
      border: 1px solid map.get($theme-colors, "light");
    }
  }

  &:hover {
    background: map.get($theme-colors, "primary");
    .card-body {
      color: map.get($theme-colors, "light");
      text-decoration: none;
    }

    .folder-icon {
      color: map.get($theme-colors, "light");
    }

    .dropdown-toggle {
      color: map.get($theme-colors, "light");
    }
  }
}

.ingredient-card {
  display: flex;
  flex-direction: row;
  min-width: 350px;
  max-width: 350px;
  .card-body {
    color: map.get($theme-colors, "dark");
  }
  .ingredient-icon {
    color: map.get($theme-colors, "primary");
  }

  .dropdown-toggle {
    border: 1px solid transparent;
    padding: 3px 10px 2px 10px;
    background-color: transparent;
    color: map.get($theme-colors, "dark");
    &:hover {
      border: 1px solid map.get($theme-colors, "light");
    }
  }

  a {
    color: map.get($theme-colors, "secondary");
  }

  &:hover {
    background: map.get($theme-colors, "primary");

    .ingredient-card-body {
      color: map.get($theme-colors, "light");
      text-decoration: none !important;
    }

    .ingredient-icon {
      color: map.get($theme-colors, "light");
    }

    .text-muted {
      color: color.adjust(map.get($theme-colors, "light"), $alpha: -0.1) !important;
    }

    .dropdown-toggle {
      color: map.get($theme-colors, "light");
    }
  }
}

//Recipe card styles
.card-recipe {
  min-width: 220px;
  max-width: 220px;

  .card-footer {
    background-color: inherit;
    border-top: none;
  }

  .dropdown-toggle {
    padding: 3px 10px 2px 10px;
  }
}

.filter-select {
  background-color: transparent;
  border-color: transparent;
  width: fit-content;
  padding: 0;
  &:disabled  {
    background-color: transparent !important;
  }
  a {
    text-decoration: none;
    color: inherit;
  }
}

.cont-ingredient {
  width: 100%;
  height: 100%;
  background-color: #fff;
  margin: 10px 20px 20px 10px;
  padding-top: 10px;
  padding-bottom: 10px;

  border: 1px lightgrey solid;
  border-radius: 4px;
  //box-shadow: 10px 10px 10px 10px rgba(119, 119, 119, 0.12);
  //-moz-box-shadow: 10px 10px 10px 10px rgba(119, 119, 119, 0.12);
  //-webkit-box-shadow: 10px 10px 10px 10px rgba(119, 119, 119, 0.12);
}

//Dashboard Users
.card-intro {
  min-width: 300px;
  .card-footer {
    background-color: inherit;
    border-top: none;
  }
}

//Text HTML Editor for notes n stuff
.ql-editor {
  min-height: 200px;
}
