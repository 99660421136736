@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    margin: 0;
  }
  #root {
    @apply h-screen;
  }

  .recipe-path-breadcrumb > .breadcrumb {
    @apply flex-nowrap overflow-x-scroll;
  }

  blockquote,
  dl,
  dd,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  hr,
  figure,
  p,
  pre {
    margin: 0;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: inherit;
    font-weight: inherit;
  }
  h1,
  h3 {
    @apply font-semibold text-2xl;
  }
}
